<template>
  <div class="waiting" v-if="!isReady && !contentReady">
    <div class="overlay overlay-loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
  </div>
  <div class="wrapper" v-else>
    <div class="content-wrapper">
      <div class="dashboard-content" :style="dashboardContentCSS">
        <DashboardEquipment
          v-show="contentReady"
          :equipmentId="connectorId"
          :screenId="screenId"
        ></DashboardEquipment>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardEquipment from "@/views/private/DashboardEquipment.vue";
import AuthService from "@/services/auth";

export default {
  name: "ContentView",
  components: {
    DashboardEquipment
  },
  data() {
    return {
      contentReady: false
      // connectorId: null,
      // screenId: null,
      // dashboardContentCSS: null
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isRulesReady() {
      return this.$store.getters["user/isRulesReady"];
    },
    isEquipmentReady() {
      return this.$store.getters.isReady;
    },
    isReady() {
      return this.isEquipmentReady && this.contract && this.isRulesReady;
    },
    connectorId() {
      return this?.params?.connector_id || null;
    },
    screenId() {
      return this?.params?.screen_id || null;
    },
    dashboardContentCSS() {
      // return {
      //   "--background-color": "transparent",
      //   "--transform": "scale(0.91)",
      //   "--width": "100%",
      //   "--margin-left": "0",
      //   "--min-height": "auto",
      //   "--height": "auto"
      // };
      try {
        if (this?.params?.wrapper?.content_css_vars) {
          return JSON.parse(this?.params?.wrapper?.content_css_vars);
        }
      } catch (error) {
        console.log(error);
      }
      return {};
    }
  },
  watch: {
    contract: {
      immediate: true,
      handler(n, o) {
        if (!o && n && !this.isEquipmentReady) {
          this.fetchScreens();
          this.fetchEquipment();
        }
      }
    }
  },
  methods: {
    async fetchScreens() {
      if (!this.contract) return;
      this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contract.id
      });
    },
    async fetchEquipment() {
      if (!this.contract) return;
      this.$root.$store.dispatch("fetchEquipmentList");
    },
    onContentReady() {
      this.contentReady = true;
    }
  },
  beforeCreate() {
    const readParams = () => {
      let params = null;
      // console.log(document.location.search);
      try {
        // TODO all configuration below will be read from _cdim
        if (this.$utils.gup("_cdim")) {
          params = JSON.parse(localStorage.getItem("_cdim"));
        }
      } catch (error) {
        console.log(error);
      }
      if (!params) {
        console.log(
          "TODO: display a message to the user that the configuration is missing"
        );
      }
      return params;
    };
    this.params = readParams();
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/static/common/css/content-view.css";
    let head = document.getElementsByTagName("head")[0];
    head.appendChild(link);
    // validate screen type
    // app.__vue__.$store.getters["dashboard/screens"].filter(({public})=>public).map(({id})=>id)
  },
  created() {
    this.$root.$on("dashboard:page_layout", this.onContentReady);
    if (this.isEquipmentReady) return;
    if (!this.user) {
      let auth = new AuthService();
      if (auth.access_token()) {
        this.$store
          .dispatch("user/login", {keep_alive: auth.keepAlive()})
          .catch(() => {
            // TODO: Display a message to the user that the session has expired
            this.$router.push("/");
          });
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$root.$off("dashboard:page_layout", this.onContentReady);
    let head = document.getElementsByTagName("head")[0];
    head.childNodes.forEach((item) => {
      if (item.href && item.href.endsWith("content-view.css"))
        item.parentNode.removeChild(item);
    });
  }
};
</script>

<style scoped>
.waiting {
  position: relative;
}
.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
  padding: 80px;
}
.opaque {
  opacity: 0.5;
}

/* custom content */
#app > .wrapper > .content-wrapper > .dashboard-content {
  background-color: var(--background-color);
  transform: var(--transform);
  width: var(--width);
  margin-left: var(--margin-left);
  min-height: var(--min-height);
  height: var(--height);
}
</style>

<style></style>
